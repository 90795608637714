<template>
    <div class="row">
        <template v-if="item.debit == null">
            <label for="formFile" class="form-label">Transfer from/Paid from</label>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Cash & Bank Account"
                    v-model="item.account_head_id"
                    :options="accountHeads"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Project"
                    v-model="item.project_id"
                    :options="projects"
                    label="name"
                    :reduce="name => name.id"
                    @option:selected="onSelectProject"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Donor"
                    class="w-100"
                    :options="donors"
                    label="name"
                    :reduce="name => name.id"
                    v-model="item.donor_id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Cost Centre"
                    v-model="item.cost_centre_id"
                    :options="costCentres"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
              <label for="colFormLabel" class="col-form-label">Business</label>
              <v-select
                  placeholder="Select Business"
                  class="w-100"
                  :options="businesses"
                  label="name"
                  :reduce="name => name.id"
                  v-model="item.business_id"
              />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
              <label for="colFormLabel" class="col-form-label">Party</label>
              <v-select
                  placeholder="Select Party"
                  class="w-100"
                  :options="contactProfiles"
                  :reduce="name => name.id"
                  label="name"
                  v-model="item.contact_profile_id"
              />
            </div>
            <div class="col-6 mt-1">
                <label for="formFileMultiple" class="form-label">Description</label>
                <input
                    v-model="item.note"
                    type="text" class="form-control"
                    placeholder="Description"
                >
            </div>
        </template>
        <template v-else>
            <label for="formFile" class="form-label">Transfer to/Received in</label>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Cash & Bank Account"
                    v-model="item.account_head_id"
                    :options="accountHeads"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Project"
                    v-model="item.project_id"
                    :options="projects"
                    label="name"
                    :reduce="name => name.id"
                    @option:selected="onSelectProject"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Donor"
                    class="w-100"
                    :options="donors"
                    label="name"
                    :reduce="name => name.id"
                    v-model="item.donor_id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
                <v-select
                    placeholder="Select Cost Centre"
                    v-model="item.cost_centre_id"
                    :options="costCentres"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
              <label for="colFormLabel" class="col-form-label">Business</label>
              <v-select
                  placeholder="Select Business"
                  class="w-100"
                  :options="businesses"
                  label="name"
                  :reduce="name => name.id"
                  v-model="item.business_id"
              />
            </div>
            <div class="cols-12 col-sm-6 col-lg-3">
              <label for="colFormLabel" class="col-form-label">Party</label>
              <v-select
                  placeholder="Select Party"
                  class="w-100"
                  :options="contactProfiles"
                  :reduce="name => name.id"
                  label="name"
                  v-model="item.contact_profile_id"
              />
            </div>
            <div class="col-6 mt-1">
                <label for="formFileMultiple" class="form-label">Description</label>
                <input
                    v-model="item.note"
                    type="text" class="form-control"
                    placeholder="Description"
                >
            </div>
        </template>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";

const $props = defineProps({
    item: {
        type: Object
    },
    index: {
        type: Number
    },
    accountHeads: {
        type: Array
    },
    projects: {
        type: Array
    },
    costCentres: {
        type: Array
    },
    contactProfiles: {
        type: Array
    },
    businesses: {
      type: Array,
      default: () => [],
    },
})
const donors = ref([]);
const projectId = computed(() => $props.item.project_id)
watch( projectId, (newValue) => {
    if(newValue === null) {
        donors.value = [];
        $props.item.donor_id = null;
    }
})
const onSelectProject = (reset = true) => {
    if(reset) {
        $props.item.donor_id = null;
    }
    donors.value = [];
    for (let project of $props.projects) {
        if(project.id === $props.item.project_id && project.party.hasOwnProperty('id')){
            donors.value.push(project.party);
            break;
        }
    }
}
onMounted( () => {
    if($props.item.donor_id)
        onSelectProject(false)
})
</script>
<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>
